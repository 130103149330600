<template>
  <common title="信息提交">
    <div class="IdentitySubmission">
      <div class="submit-t">
        <div class="submit-content">
          <div class="label">昵称：</div>
          <input type="text" v-model.trim="aoiUserInfoForm.nickName" />
        </div>
        <div class="submit-content">
          <div class="label">手机号：</div>
          <input type="text" v-model.trim="aoiUserInfoForm.mobileNum" />
        </div>
        <div class="submit-content">
          <div class="label">身份证：</div>
          <input v-model="aoiUserInfoForm.idCardNum" type="text" readonly />
        </div>
        <div class="submit-content">
          <div class="label">房号：</div>
          <div class="value">
            <div class="room" @click="isShowCas = true">
              {{ houseName }}
            </div>
          </div>
        </div>
      </div>

      <div class="submit-b">
        <div class="btn confirm" @click="confirmSure">确认提交</div>
        <div class="btn cancel" @click="$router.back()">取消返回</div>
      </div>
    </div>
    <div class="popup">
      <van-popup v-model="isShowCas" round position="bottom">
        <van-cascader
          v-model="aoiUserInfoForm.spaceId"
          :field-names="fieldNames"
          title="请选择所在房号"
          active-color="#1989fa"
          :options="areaItemList"
          @close="isShowCas = false"
          @change="change"
          @finish="onFinish"
        />
      </van-popup>
    </div>
  </common>
</template>

<script>
import Vue from "vue";
import { Cascader, Popup } from "vant";
Vue.use(Cascader);
Vue.use(Popup);
import { areaLinkUrl, aoiRegister, aoiLogin } from "./api.js";
import storage from "../utils/storage";
import common from "../components/common.vue";
export default {
  name: "IdentitySubmission",
  components: {
    common,
  },
  props: {},
  data() {
    return {
      houseName: "", //房号
      isShowCas: false, //控制选择房号弹窗
      areaItemList: [], //社区房号信息
      fieldNames: {
        text: "name",
        value: "id",
        children: "children",
      },
      aoiUserInfoForm: {
        deviceNo: storage.get('deviceNo') || '',
        nickName: "", //用户昵称
        mobileNum: "",
        realName: "", //真实姓名
        avatar: "", //用户头像
        idCardNum: "", //身份证号码
        registerType: 1, //注册方式 1身份证 2人脸 3手机号
        spaceId: "",
        sex: "",
      },
      hasChildren: true,
      isRegister: 0,
    };
  },
  created() {},
  mounted() {
    const { userInfo, isRegister } = this.$route.query;
    if (userInfo) {
      const result = eval("(" + userInfo + ")");
      this.aoiUserInfoForm.realName = result.name;
      this.aoiUserInfoForm.nickName = result.name;
      this.aoiUserInfoForm.mobileNum = result.mobileNum;
      this.aoiUserInfoForm.idCardNum = result.idNum;
      this.aoiUserInfoForm.avatar = result.avatar;
      this.aoiUserInfoForm.sex = result.sex;
      this.isRegister = isRegister;
    }
    this.getRoomList();
  },
  methods: {
    change(data) {
      console.log(data, this.aoiUserInfoForm.spaceId, "9999");
      if (data.selectedOptions && data.selectedOptions[data.tabIndex]) {
        if (!data.selectedOptions[data.tabIndex].hasChildren) {
          this.hasChildren = false;
        } else {
          this.hasChildren = true;
        }
      }
    },
    //校验字段
    verifyFields() {
      let result = true;
      if (!this.aoiUserInfoForm.nickName) {
        this.$toast({ message: "请输入昵称", duration: 5000 });
        result = false;
        return;
      }
      if (!this.aoiUserInfoForm.mobileNum) {
        this.$toast({ message: "请输入手机号", duration: 5000 });
        result = false;
        return;
      }
      if (!/^(1)\d{10}$/.test(this.aoiUserInfoForm.mobileNum)) {
        this.$toast({
          message: "请输入正确的手机号",
          duration: 3000,
        });
        return;
      }
      // 身份证号码
      if (
        !/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(
          this.aoiUserInfoForm.idCardNum
        )
      ) {
        this.$toast({ message: "请输入正确的身份证号", duration: 5000 });
        result = false;
        return;
      }
      if (!this.aoiUserInfoForm.spaceId) {
        this.$toast({ message: "请选择房号", duration: 5000 });
        result = false;
        return;
      }
      if (this.hasChildren) {
        this.$toast({ message: "请选择房号", duration: 5000 });
        result = false;
        return;
      }
      return result;
    },
    //确认提交
    confirmSure() {
      const result = this.verifyFields();
      if (result) {
        //注册
        this.aoiRegister();
      }
    },
    //身份证注册
    aoiRegister() {
      this.$axios.post(aoiRegister, this.aoiUserInfoForm).then((res) => {
        if (res.code == 200) {
          const { data } = res;
          storage.set("systemUserInfo", data);
          this.$store.commit("setUserId", data.userId);
          this.$store.commit("setHouseId", data.houseId);
          this.$store.commit("setTenantId", data.tenantId);
          this.$store.commit("setCommunityId", data.communityId);
          this.$router.push({
            name: "guidePage",
          });
        }
      });
    },

    //勾选完
    onFinish({ selectedOptions }) {
      this.isShowCas = false;
      this.houseName = selectedOptions.map((option) => option.name).join("-");
    },
    //获取房号
    getRoomList() {
      this.$axios
        .get(`${areaLinkUrl}?specifyCollectionTypes=1&topSpaceId=P1C2A2S1D1`)
        .then((res) => {
          if (res.code == 200) {
            this.areaItemList = res.data;
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
.IdentitySubmission {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 59px 331px 57px 256px;
  box-sizing: border-box;
  .submit-t {
    width: 100%;
    .submit-content {
      width: 100%;
      justify-content: center;
      display: flex;
      align-items: center;
      input {
        flex: 1;
        height: 50px;
        border-radius: 6px;
        border: 1px solid #e4e7ed;
        font-weight: 400;
        font-size: 16px;
        color: #000;
        font-style: normal;
        padding: 0 15px;
        margin-left: 15px;
        box-sizing: border-box;
        &::placeholder {
          font-weight: 400;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.5);
          font-style: normal;
        }
      }
      .value {
        margin-left: 15px;
        flex: 1;
        box-sizing: border-box;
        .room {
          width: 100%;
          height: 50px;
          border-radius: 6px;
          border: 1px solid #e4e7ed;
          box-sizing: border-box;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          line-height: 50px;
          color: #2f3a4f;
          text-align: left;
          padding: 0 15px;
        }
      }
      .label {
        width: 50px;
        white-space: nowrap;
        font-weight: 400;
        font-size: 16px;
        color: #2f3a4f;
        line-height: 22px;
        font-style: normal;
      }
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
  .submit-b {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    box-sizing: border-box;
    .btn {
      width: 239px;
      height: 50px;
      box-sizing: border-box;
      background: #1874fd;
      border-radius: 6px;
      // padding: 13px 102px;
      font-weight: 600;
      font-size: 18px;
      color: #ffffff;
      line-height: 50px;
      text-align: center;
      font-style: normal;
      &:last-child {
        margin-left: 138px;
        border: 1px solid #1874fd;
        background: #ffffff;
        color: #1874fd;
      }
    }
  }
}
</style>
